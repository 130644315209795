import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appCapitalizeFirstLetter]',
})
export class CapitalizeFirstLetterDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event.target.value']) onInput(value: string) {
    const words = value.split(' ');
    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
      return '';
    });
    this.el.nativeElement.value = capitalizedWords.join(' ');
  }
}
