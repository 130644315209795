import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { NumericInputDirective } from 'src/shared/validation/numeric-input.directive';
import { CapitalizeFirstLetterDirective } from 'src/shared/validation/capitalize-first-letter.directive';
import { TrustHtmlPipe } from 'src/shared/components/pipe/trust-html-pipe';
import { TruncateEmailPipe } from 'src/shared/components/pipe/truncate-email.pipe';
import { IndianNumberFormatPipe } from 'src/shared/components/pipe/indian-number-format.pipe';
import { EmptyListingComponent } from 'src/shared/components/empty-listing/empty-listing.component';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { TruncatePipe } from 'src/shared/components/pipe/truncate.pipe';
@NgModule({
  declarations: [
    EmptyListingComponent,
    NumericInputDirective,
    CapitalizeFirstLetterDirective,
    TrustHtmlPipe,
    TruncateEmailPipe,
    IndianNumberFormatPipe,
    TruncatePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    HttpClientModule,
    MatSnackBarModule,
    RouterModule,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    EmptyListingComponent,
    HttpClientModule,
    MatSnackBarModule,
    RouterModule,
    NumericInputDirective,
    CapitalizeFirstLetterDirective,
    TrustHtmlPipe,
    TruncateEmailPipe,
    IndianNumberFormatPipe,
    TruncatePipe,
  ],
})
export class SharedModule {}
