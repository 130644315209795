import {
  Directive,
  Input,
  ElementRef,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';

@Directive({
  selector: '[appNumericInput]',
})
export class NumericInputDirective {
  @Input() allowNumeric: boolean = true;
  @Output() numericInputValidityChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.allowNumeric) return;

    const keyCode = event.keyCode || event.which;

    // Allow navigation keys like arrows, backspace, delete, etc.
    const navigationKeys = [
      8,  // Backspace
      9,  // Tab
      13, // Enter
      27, // Escape
      46, // Delete
      37, // Left arrow
      39, // Right arrow
      36, // Home
      35, // End
    ];

    if (navigationKeys.includes(keyCode)) {
      return;
    }

    // Allow Ctrl (17) or Command (91) + A, C, V, X (65, 67, 86, 88)
    if ((event.ctrlKey || event.metaKey) && [65, 67, 86, 88].includes(keyCode)) {
      return;
    }

    // Check if the key pressed is numeric
    const isNumericInput =
      (keyCode >= 48 && keyCode <= 57) || // Numeric keys (0-9)
      (keyCode >= 96 && keyCode <= 105);  // Numpad keys (0-9)

    if (!isNumericInput) {
      event.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const cleanedValue = inputElement.value.replace(/\D/g, '');

    // If the cleaned value differs from the current value, update the input and set validity
    if (inputElement.value !== cleanedValue) {
      inputElement.value = cleanedValue;
    }

    // Emit validity based on the final cleaned value
    const isValid = cleanedValue.length > 0;
    this.emitValidity(isValid);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const clipboardData: DataTransfer | null = event.clipboardData;
    if (clipboardData) {
      const pastedText = clipboardData.getData('text');
      const isNumericInput = /^\d+$/.test(pastedText);

      if (!isNumericInput) {
        event.preventDefault();
      }
    }
  }

  private emitValidity(isValid: boolean) {
    this.numericInputValidityChange.emit(isValid);
  }
}
