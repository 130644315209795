import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateEmail',
})
export class TruncateEmailPipe implements PipeTransform {
  transform(value: string, maxLength: number = 25): string {
    if (!value) return '';

    if (value.length <= maxLength) return value;

    const [localPart, domain] = value.split('@');
    const truncatedLocalPart = localPart.slice(
      0,
      maxLength - domain.length - 5
    ); // Adjust for ellipsis and '@'
    return `${truncatedLocalPart}...@${domain}`;
  }
}
