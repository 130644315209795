import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indianNumberFormat',
})
export class IndianNumberFormatPipe implements PipeTransform {
  transform(value: number, decimalPlaces: number = 1): string {
    if (value === null || value === undefined) {
      return '';
    }

    // Format the integer part
    const parts = value.toString().split('.');
    const integerPart = parts[0];
    const decimalPart = parts[1] ? parts[1].substr(0, decimalPlaces) : '';

    let lastThreeDigits = integerPart.slice(-3);
    const otherDigits = integerPart.slice(0, -3);

    if (otherDigits !== '') {
      lastThreeDigits = ',' + lastThreeDigits;
    }

    const formattedNumber =
      otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThreeDigits;

    // Format the decimal part
    if (decimalPart) {
      return formattedNumber + '.' + decimalPart;
    } else {
      return formattedNumber;
    }
  }
}
